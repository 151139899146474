import { Result } from "antd";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
const ErrorContainer = styled.div`
  margin-top: 120px;
`;

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <ErrorContainer>
      <Result
        status="404"
        title="404"
        subTitle={t("Sorry, the page you visited does not exist.")}
      />
    </ErrorContainer>
  );
};

export default PageNotFound;
